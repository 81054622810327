$input-range-primary-color: $c-4 !default;
$input-range-neutral-color: $c-text-gray !default;
$input-range-neutral-light-color: #fff !default;
$input-range-disabled-color: #fff !default;

// input-range-slider
$input-range-slider-background: $input-range-primary-color !default;
$input-range-slider-border: 1px solid $input-range-primary-color !default;
$input-range-slider-focus-box-shadow-radius: 5px !default;
$input-range-slider-focus-box-shadow-color: transparentize(
    $input-range-slider-background,
    0.8
) !default;
$input-range-slider-height: 3.6rem !default;
$input-range-slider-width: 2.8rem !default;
$input-range-slider-transition: transform 200ms $ease-card;
$input-range-slider-container-transition: left 200ms ease-out !default;
$input-range-slider-active-transform: scale(1.1) !default;
$input-range-slider-disabled-background: $input-range-disabled-color !default;
$input-range-slider-disabled-border: 1px solid $input-range-disabled-color !default;

// input-range-label
$input-range-label-color: $input-range-neutral-color !default;
$input-range-label-font-size: 0.8rem !default;
$input-range-label-position-bottom: -1.4rem !default;
$input-range-label-value-position-top: -1.8rem !default;

// input-range-track
$input-range-track-background: $input-range-neutral-light-color !default;
$input-range-track-height: 3.2rem !default;
$input-range-track-transition: left 0.3s ease-out, width 0.3s ease-out !default;
$input-range-track-active-background: $input-range-primary-color !default;
$input-range-track-disabled-background: $input-range-neutral-light-color !default;

.input-range {
    position: relative;
    height: 3.6rem;
    width: 100%;
    border-radius: 0.375rem;
}

.input-range__label-container {
    left: -50%;
    position: relative;
    background-color: $c-4;
    padding: $base * 0.5;
    border-radius: 0.375rem;
    font-size: 18px;
    .input-range__label--max & {
        left: 50%;
    }
}

.input-range__label {
    color: $c-white;
    font-size: $input-range-label-font-size;
    transform: translateZ(0);
    white-space: nowrap;
    display: none;
}

.input-range__label--min,
.input-range__label--max {
    bottom: $input-range-label-position-bottom;
    position: absolute;
    display: none;
}

.input-range__label--min {
    left: 0;
}

.input-range__label--max {
    right: 0;
}

.input-range__label--value {
    position: absolute;
    top: -65px;

    // top: $input-range-label-value-position-top;
}

//.input-range__label
//border: 4px solid lighten($c--blue, 10%);
// background-color : darken($c--blue, 10%);
.input-range__slider {
    appearance: none;
    background-color: $c-3;
    // border: $base solid darken($c-2, 5%);
    border-radius: 0.375rem;
    cursor: pointer;
    display: block;
    height: $input-range-slider-height;
    margin-left: $input-range-slider-width / -2;
    margin-top: $input-range-slider-height / -2 + $input-range-track-height / -2;
    outline: none;
    position: absolute;
    top: 50%;
    // box-shadow: 0 0 2px 0 darken($c-2, 15%);
    transition: $input-range-slider-transition;
    width: $input-range-slider-width;

    &:active {
        transform: $input-range-slider-active-transform;
    }

    // &:focus {
    //     box-shadow: 0 0 0 $input-range-slider-focus-box-shadow-radius
    //         $input-range-slider-focus-box-shadow-color;
    // }

    .input-range--disabled & {
        background: #b9975b;
        // border: $input-range-slider-disabled-border;
        box-shadow: none;
        transform: none;
    }
}

.input-range__slider-container {
    transition: $input-range-slider-container-transition;
}

.input-range__track {
    background: $c-4;
    border-radius: 0.375rem;
    cursor: pointer;
    display: block;
    height: $input-range-track-height;
    position: relative;
    transition: $input-range-track-transition;

    .input-range--disabled & {
        background: $input-range-track-disabled-background;
    }
}

.input-range__track--background {
    left: 0;
    margin-top: -0.5 * $input-range-track-height;
    position: absolute;
    right: 0;
    top: 50%;
    background-color: $c-white;
}

.input-range__track--active {
    background: $input-range-track-active-background;
}

// .input-range__label {
//     display: none;
// }
