.progress {
    position: relative;
    width: 100%;
    height: $base * 2.5;
    background-color: $c-white;

    overflow: hidden;
}

.progress-inner {
    position: absolute;
    left: 0;
    height: 100%;
    width: 0%;
    background-color: $c-2;
    &::after {
        content: "";
        position: absolute;
        right: -0.5em;
        height: 100%;
        width: 1em;
        background-color: $c-2;
        transform: skewX(-25deg);
    }
}
