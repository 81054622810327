.icon {
    display: inline-flex;
    stroke-width: 2;
    vertical-align: middle;
    fill: none;
    overflow: visible;
}

.icon-s {
    height: $base * 1.5;
    width: $base * 1.5;
}

.icon-sm {
    height: $base * 2;
    width: $base * 2;
}

.icon-m {
    height: $base * 2.2;
    width: $base * 2.2;
}

.icon-l {
    height: $base * 3.5;
}

.spin {
    will-change: transform;
    animation: spin 1500ms infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
