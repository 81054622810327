button,
a {
    -webkit-appearance: none !important;
    writing-mode: none !important;
    border: none;
    background-color: transparent;
    box-shadow: none;
    font-weight: 800;
    font-family: inherit;
    font-size: inherit;
    outline: none !important;
    margin: 0;
    padding: 0;
    text-decoration: none;
    &:hover {
        cursor: pointer;
    }
    &:disabled {
        cursor: not-allowed;
        pointer-events: all !important;
    }
}

.btn {
    position: relative;
    display: inline-block;
    font-size: 24px;
    height: 3.6rem;
    padding: 0 $base * 2;
    background-color: $c-1;
    color: $c-white;
    border-radius: 0.375rem;
}

.btn-select {
    position: relative;
    height: $base * 6;
    width: $base * 6;
    margin: 0 auto;
}

.btn-inner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// .bg-1 {
//     background-color: lighten($c-border-gray, 10%);
//     border: 4px solid $c-border-gray;
// }
// .bg-2 {
//     background-color: $c-2;
//     border: 4px solid darken($c-2, 10%);
// }

.bg-disabled {
    // background-color: $c-2;
    // box-shadow: 0 4px 0 0 darken($c-text-gray, 5%);
    // &:active {
    //     box-shadow: 0 4px 0 0 darken($c-text-gray, 0%);
    // }
}

.z-index-minus {
    z-index: -1;
}

.btn-game {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: $base * 4.6;
    padding: 0 $base * 1;
    background-color: $c-1;
    color: $c-white;
}

.btn-inline {
    line-height: $base * 3.2;
}
