.c-w {
    color: $c-white;
}

.c-text {
    color: $c-text;
}

.c-gray {
    color: $c-text-gray;
}

.c-sec {
    color: $c-2;
}

.c-1 {
    color: $c-1;
}
