.card {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: $base * 20;
    user-select: none;
    @include mq(laptop) {
        height: $base * 25;
    }
}

.card-height {
    height: $base * 20;
    @include mq(laptop) {
        height: $base * 25;
    }
}

.card-question {
    background-color: lighten($c-1, 5%);
    padding: $base;
    height: 100%;
    color: $c-white;
    border-radius: 0.375rem;
    width: 100%;
}

.card-competition {
    padding: $base;
    min-height: $base * 12;
    color: $c-white;
    border-radius: 0.375rem;
    margin-top: $base;
    width: 100%;
}

.bg-1 {
    background-color: $c-1;
}

.bg-2 {
    background-color: $c-2;
}
.bg-3 {
    background-color: $c-3;
}

.bg-4 {
    background-color: $c-4;
}
