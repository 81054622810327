input,
textarea {
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: none;
    font-size: inherit;
    font-family: inherit;
    overflow: visible;
    line-height: inherit;
    box-shadow: none;
    &:focus,
    &:active {
        outline: none;
    }
}

textarea {
    resize: vertical;
}

.input {
    display: flex;
    width: 100%;
    padding: 0 $base;
    color: $c-text;
    background-color: $c-white;
    height: 3.6rem;
    border: 3px solid $c-border-gray;
    font-weight: 800;
    border-radius: 0.375rem;
    &::placeholder {
        color: $c-text-gray;
    }
    &:focus {
        border-color: $c-1;
    }
}

.textarea {
    display: flex;
    width: 100%;
    padding: $base * 1.2 $base;
    color: $c-text;
    background-color: $c-white;

    border: 3px solid $c-border-gray;
    font-weight: 800;
    border-radius: 0.375rem;
    &::placeholder {
        color: $c-text-gray;
    }
    &:focus {
        border-color: $c-1;
    }
}
