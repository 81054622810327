.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: $c-white;
}

.modal-navigation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: $base * 7;
    z-index: 10;
}

.modal-container {
    position: relative;
    width: 100%;
    @include mq(laptop) {
        margin: 0 auto;
        width: $base * 40;
    }
}

.modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
