$c-text: #000000;
$c-text-gray: #b4b4b4;
$c-white: #ffffff;

$c-border: #ececec;
$c-border-gray: #dbdee0;

// Colors
$c-1: #39b54a;
$c-2: #009245;
$c-3: rgba(244, 156, 36, 1);
$c-4: rgba(248, 182, 97, 1);
