.loading {
    opacity: 0;
}

.loaded {
    opacity: 1;
}

.img-animated {
    transition: opacity 5000ms $ease-slow;
    will-change: opacity;
}

.img-background {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 55%;
}
