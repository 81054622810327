.select {
    width: 100%;
    color: $c-text;
    background-color: $c-white;
    border: 3px solid $c-1;
    overflow: hidden;
    border-radius: 0.375rem;
}

.select-trigger {
    height: 3.1rem;
    padding: 0 $base;
}

.option {
    height: 3.6rem;
    width: 100%;
    padding: 0 $base;
    font-weight: 800;
    transition: 350ms $ease-slow;
    &:hover {
        background-color: $c-1;
        color: $c-white;
    }
}
