.notification {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: $base * 1;
    height: $base * 4.6;
    background-color: $c-1;
    will-change: transform;
    z-index: 15;
}
