.label {
    position: absolute;
    font-weight: 800;
}
.label-min {
    top: -$base * 1.5;
    left: 0;
}
.label-max {
    right: 0;
    top: -$base * 1.5;
}
