// Top
.pt {
    padding-top: $base;
}

.pt-m {
    padding-top: $base * 2;
}

.pt-s {
    padding-top: $base * 0.5;
}

.pt-l {
    padding-top: $base * 5;
}

// Bottom
.pb {
    padding-bottom: $base;
}

.pb-m {
    padding-bottom: $base * 2;
}

.pb-s {
    padding-bottom: $base * 0.5;
}

.pb-l {
    padding-bottom: $base * 5;
}

.pb-xl {
    padding-bottom: $base * 6;
}

// Left
.pl {
    padding-left: $base;
}

.pl-s {
    padding-left: $base * 0.5;
}

// Right
.pr {
    padding-right: $base;
}

.pr-s {
    padding-right: $base * 0.5;
}

.p-box {
    padding: $base * 2;
}

.p-box-l {
    padding: $base * 2;
    @include mq(laptop) {
        padding: $base * 4;
    }
}

.pb-mobile {
    padding-bottom: $base * 2;
    @include mq(laptop) {
        padding-bottom: $base * 0;
    }
}

.pt-mobile {
    padding-top: $base * 4;
    @include mq(laptop) {
        padding-top: $base * 0;
    }
}
