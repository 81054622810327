.tag {
    display: inline-flex;
    padding: $base * 0.5 $base;
    color: $c-white;
    background-color: $c-2;
    border-radius: 0.375rem;
}

.ab {
    position: absolute;
    top: $base * 1.2;
    right: $base * 3.5;
}
