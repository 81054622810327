// .bg-1 {
//     background-color: $c-1;
// }

// .bg-2 {
//     background-color: $c-2;
// }

.bt {
    border-top: 2px solid #e5e5e5;
}
